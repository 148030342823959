import React, { useState } from "react"

import SEO from "../components/seo"
import LayoutCard from "../components/LayoutCard"
import { cardTitle } from "assets/jss/material-kit-react.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import Button from "components/CustomButtons/Button.jsx"
import { useStaticQuery } from "gatsby"
import { useFormik } from "formik"
import { Alert, message } from "antd"

const styles = {
  cardTitle,
}

const Cotizador = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMenuCsv {
        edges {
          node {
            descripcion
            img
            nombre
            precio
            tipo
          }
        }
      }
    }
  `)
  const menu = data.allMenuCsv.edges
  const error = () => {
    message.config({
      top: 100,
    })
    message.error(
      "Debe seleccionar Carnes y bebidas. Guarniciones y ensaladas son opcionales "
    )
  }
  const formik = useFormik({
    initialValues: {
      cmbCarnes: "",
      cmbEnsaladas: "",
      cmbGuarniciones: "",
      cmbBebidas: "",
      noPlatos: 1,
    },
  })
  const [showAlert, setShowAlert] = useState(false)
  const [montoTotal, setMontoTotal] = useState(0)
  const calcular = values => {
    if (values.cmbCarnes === "" || values.cmbBebidas === "") {
      error()
      return
    }
    setShowAlert(true)

    var pBebida = parseFloat(values.cmbBebidas)
    var pCarne = parseFloat(values.cmbCarnes)
    var pGuarnicion = parseFloat(
      values.cmbGuarniciones === "" ? 0 : values.cmbGuarniciones
    )
    var pEnsalada = parseFloat(
      values.cmbEnsaladas === "" ? 0 : values.cmbEnsaladas
    )
    var noPlatos = parseFloat(values.noPlatos)
    var suma = pBebida + pCarne + pGuarnicion + pEnsalada
    var total = suma * noPlatos
    setMontoTotal(total)
  }
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      calcular(formik.values)
    }
  }
  return (
    <>
      <SEO title="Cotizador en línea" />
      <LayoutCard>
        <section className="bg-light py-5 rounded" id="cotizacion">
          <div className="container">
            <div class="section section-contacts">
              <div class="row">
                <div class="col-md-8 mx-auto">
                  <form id="cotizador">
                    <h2 class="text-center title text-dark">
                      Cotizador en línea
                    </h2>
                    <AllSelects menu={menu} formik={formik} />
                    <div className="form-row pb-3">
                      <div className="col">
                        <label>No. de platos</label>
                        <input
                          id="noPlatos"
                          name="noPlatos"
                          type="number"
                          className="form-control"
                          min="1"
                          onChange={formik.handleChange}
                          value={formik.values.noPlatos}
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                    </div>
                    <div id="btnCalcular" className="form-group">
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => calcular(formik.values)}
                      >
                        Calcular
                      </Button>
                    </div>
                  </form>
                  {showAlert && (
                    <Alert
                      message={`Q${montoTotal}.00`}
                      description="Es el total de la cotización."
                      type="success"
                      showIcon
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutCard>
    </>
  )
}

export default withStyles(styles)(Cotizador)

const MyOption = props => {
  return (
    <option value={props.data.node.precio}>
      {props.data.node.nombre} - Q{props.data.node.precio}
    </option>
  )
}

const MySelect = props => {
  return (
    <div className="col-md-6">
      <label>{props.name}</label>
      <select
        className="form-control"
        id={`cmb${props.name}`}
        name={`cmb${props.name}`}
        onChange={props.formik.handleChange}
      >
        <MyOptionOne />
        {props.menu.map(function(e) {
          return e.node.tipo === props.tipoSelect && <MyOption data={e} />
        })}
      </select>
    </div>
  )
}

const MyOptionOne = () => {
  return (
    <option hidden disabled selected value>
      {" "}
      -- Selecciona una opción --{" "}
    </option>
  )
}

const AllSelects = props => {
  return (
    <>
      <div className="form-row pt-3">
        <MySelect
          name="Carnes"
          formik={props.formik}
          tipoSelect="c"
          menu={props.menu}
        />
        <MySelect
          name="Ensaladas"
          formik={props.formik}
          tipoSelect="e"
          menu={props.menu}
        />
      </div>
      <div className="form-row py-3">
        <MySelect
          name="Guarniciones"
          formik={props.formik}
          tipoSelect="g"
          menu={props.menu}
        />
        <MySelect
          name="Bebidas"
          formik={props.formik}
          tipoSelect="b"
          menu={props.menu}
        />
      </div>
    </>
  )
}
